import { useDebounce } from '@eltoro-ui/hooks'
import { useEffect, useState } from 'react'
import { Snapshot, useGotoRecoilSnapshot, useRecoilSnapshot } from 'recoil'

// this hook tracks recoil's snapshots which are the entire state of the recoil context at that moment
// user is then able to undo or redo a change made to recoil specific state
// an optional `loadPoint` snapshot may be passed to the hook if the user wants to load a specific saved snapshot
export const useTimeTravel = (loadPoint?: Snapshot) => {
  const snapshot = useRecoilSnapshot()
  const debouncedSnapshot = useDebounce(snapshot, 200)
  const [past, setPast] = useState<Snapshot[]>([])
  const [present, setPresent] = useState<Snapshot>(debouncedSnapshot)
  const [future, setFuture] = useState<Snapshot[]>([])

  useEffect(() => {
    if (
      past.every((snap) => snap.getID() !== debouncedSnapshot.getID()) &&
      present.getID() !== debouncedSnapshot.getID()
    ) {
      set(debouncedSnapshot)
    }
  }, [debouncedSnapshot])

  const gotoSnapshot = useGotoRecoilSnapshot()

  const canUndo = past.length > 0
  const canRedo = future.length > 0

  const undo = () => {
    if (!canUndo) return
    const [previous] = past.slice(-1)
    const newPast = past.slice(0, -1)
    setPast(newPast)
    setPresent(previous)
    setFuture([present, ...future])
    gotoSnapshot(previous)
  }

  const redo = () => {
    if (!canRedo) return
    const [next] = future
    const newFuture = future.slice(1)
    setPast([...past, present])
    setPresent(next)
    setFuture(newFuture)
    gotoSnapshot(next)
  }

  const set = (newPresent: Snapshot) => {
    setPast([...past, present])
    setPresent(newPresent)
    setFuture([])
  }

  useEffect(() => {
    if (loadPoint) {
      gotoSnapshot(loadPoint)
      setFuture([])
    }
  }, [loadPoint])

  return {
    undo,
    canUndo,
    redo,
    canRedo,
    present,
  }
}
